<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ISTP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Seseorang yang berkepribadian ISTP sering disebut The Mechanics atau mekanik atau bisa disebut pengrajin. Pribadi yang termasuk ISTP sekitar 4-6% populasi di dunia. Dimana menurut dimensinya ISTP berarti Introversion atau seseorang yang tidak suka akan hubungan dan dunia luar. Sensing yaitu mereka yang mengenal hal-hal secara konkret atau nyata Thinking merupakan dimensi dasar mereka dimana mereka suka menilai dan berpikir sebelum bertindak dan sangat objektif. Perception yakni pemikiran yang terbuka dan cenderung menunda keputusan demi kesepakatan. 
          </p>
          <p>
            Ciri kepribadian ISTP adalah:
            <ul>
              <li> Terus menerus mengumpulkan fakta tentang lingkungan yang ada.</li>
              <li> Memiliki kemampuan yang baik dalam menerapkan logika.</li>
              <li> Turun tangan langsung merupakan cara belajar yang baik. </li>
              <li> Biasanya mampu menguasai teori dan pemikiran yang abstrak.</li>
              <li> Personality yang cukup kompleks.</li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Observasi pada kehidupan sosial dan apa yang membuat orang memiliki emosi yang berbeda.
              </li>
              <li>
                Belajar untuk mengenali perasaan anda dan mengekspresikannya.
              </li>
              <li>
                Jangan mencari kesalahan untuk menyelesaikan masalah.
              </li>
              <li>
                Jangan menyimpan informasi yang harusnya dibagikan pada orang lain.
              </li>
              <li>
                Pekerjaan yang cocok adalah polisi, ahli forensic, mekanik, pilot, pemahat dan pengusaha.
              </li>
            </ul>
          </p>
          <p>
            Asmara untuk ISTP adalah mereka yang memiliki kepribadian ESTJ atau ENTJ yang bisa meredam dominansi dari sifat ISTP dan menyeimbangkannya.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ISTP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Introversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="introversion">Introversion</h5>
              <p>cenderung pendiam dan suka menyendiri</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Thinking.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="thinking">Thinking</h5>
              <p>membuat keputusan berdasarkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>cenderung membiarkan pilihan tetap terbuka</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ISTP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ISTP',
        slug: 'istp'
      }
    }
  }
};
</script>

<style scoped>

</style>
